import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse, HttpClient, HttpHeaders } from '@angular/common/http';
import { SkipInterceptorService } from './skip-interceptor.service';
import { environment } from 'src/environments/environment';



@Injectable()

export class GlobalErrorHandlerService implements ErrorHandler {
  ipAddress;
  apiRoot;
  constructor(
    private _skipInterceptor: SkipInterceptorService,

    private _httpClient: HttpClient, ) {
    this.apiRoot = environment.apiURl;
    this.getExternalIp();
  }

  async getExternalIp() {
    await this._skipInterceptor.getIPRequest('https://jsonip.com')
      .subscribe((data: any) => {
        this.ipAddress = data.ip;
      });
  }


  handleError(err: HttpErrorResponse | any) {
    const today = new Date().toISOString()
    const obj = {
      "type": "LOG",
      "data": {
        "body": err.url,
        "externalIPAddress": this.ipAddress,
        "localIPAddress": this.ipAddress,
        "logDate": today,
        "tag": "Ref-Check",
        "code": err.status + ' ' + err.statusText,
        "message": err.message,
        "stack": err.url
      }
    }
    const bodyData = JSON.stringify(obj)
    const headerDict = {
      "Content-Type": "application/json",
      "x-api-key": environment.refreshtokenKey
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.postRequest(`${environment.refreshToken}`, bodyData, requestOptions.headers);
  }

  postRequest(url: string, body: any, headers?: HttpHeaders) {
    let request;
    if (headers) {
      request = this._httpClient.post(url, body, { headers });
    }
    else {
      request = this._httpClient.post(url, body);
    }
    return request.toPromise().then(res => {
      return res;
    });

  }


} 