import { CanActivateChild, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { UserService } from '../user.service';
import { AuthService } from './auth.service';
import { LoadingService } from '../loading.service';

@Injectable()
export class AuthGuard implements CanActivateChild {
  refershTokenKey: any;

  constructor(
    private cookieService: CookieService,
    private userService: UserService,
    private authService: AuthService,
    private loadingService: LoadingService,
    private _router: Router
  ) {
  }

  async canActivateChild(): Promise<any> {
    if (this.cookieService.get('_at')) {
        return true;
    } else {
        if (this.cookieService.get('unicode')) {
            const body = {
                "data": {
                    "query": {
                        "term": {
                            "code.keyword": {
                                "value": this.cookieService.get('unicode')
                            }
                        }
                    }
                },
                "type": "GET"
            }
            await this.authService.refreshToken(body).then(data => {
                if (data) {
                    if (data[0]._source.token) {
                        this.refershTokenKey = data[0]._source.token;
                    }
                }
            });
            if (this.refershTokenKey) {
                await this.authService.getRefreshToken(this.refershTokenKey).then(data => {
                    if (data.access_token) {
                        this.cookieService.set('_appauth', data.access_token, { domain: environment.domain });
                        this.cookieService.set('_at', data.access_token);
                    }
                });
                await this.userService.getUserLoginDetails(this.cookieService.get('_appauth') !== "" ?
                    this.cookieService.get('_appauth') : this.cookieService.get('_at')).then(userData => {
                        if (userData && Object.keys(userData).length !== 0) {
                            window.localStorage.setItem('userName', userData.email.split("@")[0]);
                            window.localStorage.setItem('email', userData.email);
                            window.localStorage.setItem('phone_number', userData.phone_number);
                        }
                    });
                this.loadingService.startTimer();
                return true;
            } else {
                this.cookieService.deleteAll();
                localStorage.clear();
                window.location.href = `https://${environment.socialRedirect_URI}/logout?response_type=${environment.response_type}&client_id=${environment.client_id}&redirect_uri=${environment.redirect_uri}`;
                return false;
            }
         
        }
    }
}

}
