import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private cookieService: CookieService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.httpInterceptor(req, next));
  }
  async httpInterceptor(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const token = this.cookieService.get('_at');
    //const token = 'eyJraWQiOiJrNFppc2tYQ0xQcGFGVUpXbHk4WEJNUERcL3M2WnBcL0RXWWt6T0hFOG1mWHc9IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiJjNzU5NDQxYi1jYTZiLTQ4YmUtODg1MC0yYjU2MGUxNzE5MTMiLCJldmVudF9pZCI6IjBlNDljYjM4LTBmMmYtNGE3Yy1hNDEwLWIyMWNiMjU3NTRjZCIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4gcGhvbmUgZ2xvYmFsaHVudGluZGlhLmNvbVwvYXBpIG9wZW5pZCBwcm9maWxlIGVtYWlsIiwiYXV0aF90aW1lIjoxNjIzNjY1OTIyLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGgtMS5hbWF6b25hd3MuY29tXC9hcC1zb3V0aC0xX1FXSkhBcE9DSSIsImV4cCI6MTYyMzY2OTUyMiwiaWF0IjoxNjIzNjY1OTIyLCJ2ZXJzaW9uIjoyLCJqdGkiOiJjMGRlNTExOC04YmY2LTQ2NTktYjFhNS02NGQzYTZlYTY0NzQiLCJjbGllbnRfaWQiOiI2MHVxa2s2NGtoanQ4Z2w4NmZibW5jZmlrNSIsInVzZXJuYW1lIjoiYzc1OTQ0MWItY2E2Yi00OGJlLTg4NTAtMmI1NjBlMTcxOTEzIn0.eQnGTLR6ncbtrV5jcl5iAriFOwBOTHH2MjL9EEkNuyha9gdh45LuQJE6_H7SFd8SGbZhYcf1srSVZXWv1Rpqe26E3AVk-IglIJHoRAs_Je9PWBCpXDqAJdyUFmeo99zK7HcmuaYY2wsy59ZMqnwKjopLX_Puu_RGoxxw4yKAF8G5MCMOOcwiMAh7uKqMHkVayzVQ6XUtTiripnpany6CQZdX9jU_aC1czQPrbS46kRBiQQRGHlf9-dJ9Y9KqYcGTQ8HH5a0_9CoIW6EE3TLOe_8Jn7fVYYVqqVcVz652AdVrd0krAMP0YslAplDtfLKWcUtcrvKjeLytbRF6S_f41A'
    if (token) {
      const headers = req.headers.set('Authorization', `Bearer ${token}`);
      const authReq = req.clone({ headers });
      return next.handle(authReq).toPromise();
    }
  }
}

