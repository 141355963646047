import { Injectable } from '@angular/core';
import { SkipInterceptorService } from './skip-interceptor.service';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ApiService } from './api-service';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public text = new Subject();
  public data = new Subject();
  contentType = "application/json";

  private user = new BehaviorSubject<any>({});
  userAccess = this.user.asObservable();

  constructor(private _skipInterceptor: SkipInterceptorService,
    private _apiService: ApiService) { }

  /* User Details API*/
  getUserLoginDetails(bearerToken) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${bearerToken}`,
      "Content-Type": this.contentType
    });
    return this._skipInterceptor.getRequest(`${environment.tokenEndPoint}oauth2/userInfo`, headers)
  }

  /* Get attached candidate API */
  getAttachedCandidate(collectionName: string, perpage: number, next: number, body: any) {
    return this._apiService.postRequest(`${environment.apiURl}${collectionName}?perpage=${perpage}&pageno=${next}&${environment.urlSource}`, body);
  }

  /* Masters API */
  getMastersData(collectionName: string, perpage: number, next: number, body: any) {
    return this._apiService.postRequest(`${environment.apiURl}masterdata/${collectionName}?perpage=${perpage}&next=${next}&sort=createdDate&${environment.urlSource}`, body);
  }
  getJobData(collectionName: string, perpage: number, next: number, body: any) {
    return this._apiService.postRequest(`${environment.apiURl}masterdata/${collectionName}?refCheckStatus=true&perpage=${perpage}&next=${next}&sort=postedDate&${environment.urlSource}`, body);
  }
  getMastersRequest(collectionName: string, perpage: number, next: number, body: any) {
    return this._apiService.postRequest(`${environment.apiURl}masterdata/${collectionName}?perpage=${perpage}&next=${next}&sort=lastModified&${environment.urlSource}`, body);
  }
  addMastersData(collectionName, body) {
    return this._apiService.postRequest(`${environment.apiURl}postData/${collectionName}?${environment.urlSource}`, body);
  }
  updateDeleteData(collectionName, id, body) {
    return this._apiService.putRequest(`${environment.apiURl}putData/${collectionName}/${id}?${environment.urlSource}`, body);
  }
  /* Masters API without pagination */
  getData(collectionName: string, body: any) {
    return this._apiService.postRequest(`${environment.apiURl}masterdata/${collectionName}?${environment.urlSource}`, body);
  }

  /* Bulk post API */
  addMastersDataBulk(collectionName, body) {
    return this._apiService.postRequest(`${environment.apiURl}bulkPost/${collectionName}?${environment.urlSource}`, body);
  }
  addQuestionariesQuestion(collectionName, id, body) {
    return this._apiService.postRequest(`${environment.apiURl}bulkPost/${collectionName}?id=${id}&${environment.urlSource}`, body);
  }
  // getATSJob(username, perpage: number, next: number, body: any) {
  //   return this._apiService.getRequest(`${environment.atsJoburl}jobsByUsername?username=${username}&perpage=${perpage}&next=${next}&${environment.urlSource}`,body);
  // }
  getATSJob(username, body: any) {
    return this._apiService.postRequest(`${environment.atsJoburl}dynamicDataJobs?source=test&username=${username}`, body);
  }
  getATSJobCandidate(perpage: number, next: number, body) {
    return this._apiService.postRequest(`${environment.attachedCandidate}getmultisearch/attached/candidate?relation=objectId&perpage=${perpage}&next=${next}&source=${environment.urlSource}`, body);
  }
  updateCandidateVerification(collectionName, body) {
    return this._apiService.putRequest(`${environment.apiURl}bulkUpdate/${collectionName}?${environment.urlSource}`, body)
  }
  /* Triggering Mail Confirm API */
  getIsConfirmed(id: any) {
    return this._apiService.getRequest(`${environment.apiURl}isReferenceCheck/${id}?${environment.urlSource}`);
  }
  // DOWNLOAD CSV
  downloadCsvOptions(header, filename) {
    const csvOptions = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      showTitle: true,
      title: `Your download List : ${filename}`,
      useBom: true,
      noDownload: false,
      headers: header
    };
    return csvOptions;
  }

  /* Get API for Mail Template */
  getMailTemplate() {
    return this._apiService.getRequest(`${environment.apiURl}getTemplate/${environment.mailTemplatekey}?${environment.urlSource}`);
  }
  postTemplate(body: any) {
    return this._apiService.postRequest(`${environment.apiURl}postUpdateTemplate?${environment.urlSource}`, body);
  }

  getHeaderData(perpage, next) {
    return this._apiService.getRequest(
      `${environment.atsJoburl}/userdailyheader?source=test&perpage=${perpage}&next=${next}&username=${localStorage.getItem('userName')}`,
    );
  }

  // --------------------isAdmin----------------------

  isAdminUserget() {
    return this._apiService.getRequest(`${environment.atsJoburl}getuserdatatest?source=test&username=${localStorage.getItem('userName')}`);
  }

  isAdminUser(body: any) {
    return this._apiService.postRequest(`${environment.atsJoburl}getuserdatatest?source=test&username=${localStorage.getItem('userName')}`, body);
  }

  //--------------------update ref-check status---------

  refCheckStatus(collectionName, body) {
    return this._apiService.putRequest(`${environment.apiURl}/${collectionName}?${environment.urlSource}`, body)
  }

  setUser(user: any) {
    this.user.next(user);
  }
}
