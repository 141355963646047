import { Injectable } from '@angular/core';
import { HttpBackend, HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SkipInterceptorService {

  httpClient: HttpClient;
  apiRoot: string;

  constructor(handler: HttpBackend, private _Router: Router) {
    this.httpClient = new HttpClient(handler);
  }

  postRequest(url: string, body: any, headers?: HttpHeaders) {
    let request;
    if (headers) {
      request = this.httpClient.post(url, body, { headers });
    } else {
      request = this.httpClient.post(url, body);
    }
    return request
      .toPromise()
      .then(res => {
        return res;
      })
      .catch(err => {
        this.handleError(err);
        return {};
      });
  }

  getRequest(url: string, headers?: HttpHeaders) {
    let request;
    if (headers) {
      request = this.httpClient.get(url, { headers });
    } else {
      request = this.httpClient.get(url);
    }
    return request
      .toPromise()
      .then(res => {
        return res;
      })
      .catch(err => {
        this.handleError(err);
        return {};
      });
  }
  putRequest(url: string, body: any, headers?: HttpHeaders) {
    let request;
    if (headers) {
      request = this.httpClient.post(url, body, { headers });
    } else {
      request = this.httpClient.post(url, body);
    }
    return request
      .toPromise()
      .then(res => {
        return res;
      })
      .catch(err => {
        this.handleError(err);
        return {};
      });
  }
  getIPRequest(url: string) {
    return this.httpClient.get(url);
  }

  handleError(err: HttpErrorResponse | any) {
    if (err.status === 500) {
     // alert("Server not available");
      this._Router.navigate(['/redirecting']);
    } else if (err.status === 404) {
      // alert("Method/API not available");
    }
  }
}
