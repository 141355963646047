import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { AuthService } from './auth/auth.service';
import { LoadingService } from './loading.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SharingDataService {
    //Observable source
    private verificationCheckSourcs = new BehaviorSubject<any>('');
    refershTokenKey: any;

    constructor(private cookieService: CookieService,
        private userService: UserService,
        private authService: AuthService,
        private loadingService: LoadingService,
        private _router: Router) {

    }
    //Observable source
    verificationData$ = this.verificationCheckSourcs.asObservable();
    getVerificationData(value) {
        this.verificationCheckSourcs.next(value);
    }
    async getToken(): Promise<any> {
        const body = {
            data: {
                query: {
                    term: {
                        'code.keyword': {
                            value: this.cookieService.get('unicode')
                        }
                    }
                }
            },
            type: 'GET'
        };
        await this.authService.refreshToken(body).then(data => {
            if (data.length >= 1) {
                if (data[0]._source.token) {
                    this.refershTokenKey = data[0]._source.token;
                }
            } else {
                this.redirectToLogout();
            }
        });
        if (this.refershTokenKey) {
            await this.authService.getRefreshToken(this.refershTokenKey).then(data => {
                if (data.access_token) {
                    this.cookieService.set('_appauth', data.access_token, { domain: environment.domain });
                    this.cookieService.set('_at', data.access_token, { domain: environment.domain });
                }
            });
            await this.userService.getUserLoginDetails(
                this.cookieService.get('_appauth') !== '' ? this.cookieService.get('_appauth') : this.cookieService.get('_at'))
                .then(userData => {
                    if (userData && Object.keys(userData).length !== 0) {
                        window.localStorage.setItem('userName', userData.email.split('@')[0]);
                        window.localStorage.setItem('email', userData.email);
                        window.localStorage.setItem('phone_number', userData.phone_number);
                    }
                });
        }
        this._router.navigate(['/app']);
        this.loadingService.startTimer();
    }

    redirectToLogin(): any {
        this.cookieService.deleteAll('/', environment.domain);
        this.cookieService.delete('_appauth');
        this.cookieService.delete('_at');
        this.cookieService.delete('unicode');
        localStorage.clear();
        localStorage.setItem('clearStorage', 'true');
        window.location.href = `https://${environment.socialRedirect_URI}/login?response_type=${environment.response_type}&client_id=${environment.client_id}&redirect_uri=${environment.redirect_uri}`;
    }

    redirectToLogout(): any {
        this.cookieService.deleteAll('/', environment.domain);
        this.cookieService.delete('_appauth');
        this.cookieService.delete('_at');
        this.cookieService.delete('unicode');
        localStorage.clear();
        localStorage.setItem('clearStorage', 'true');
        window.location.href = `https://${environment.socialRedirect_URI}/logout?response_type=${environment.response_type}&client_id=${environment.client_id}&redirect_uri=${environment.redirect_uri}`;
    }
}