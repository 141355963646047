export const environment = {
  production: true,
  client_id: "3c1arqsfpnv8dfajm11bn72efq",
  aws_cognito_identity_pool_id:
    "ap-south-1:ef59d210-6023-43c5-bf10-dacf197039b6",
  aws_cognito_region: "ap-south-1",
  aws_user_pools_id: "ap-south-1_stZnl3OGB",
  domain: ".globalhuntindia.com",
  redirect_uri: "https://h3.globalhuntindia.com/",
  socialRedirect_URI: "signindev.globalhuntindia.com",
  response_type: 'code',
  refreshtokenKey: "kJfsNl8vSiPH2uaNVCuPaFs8GWfLSBka0JaHYA7h",
  /* api urls */
  tokenEndPoint: "https://signindev.globalhuntindia.com/",
  refreshToken: "https://devapis.globalhuntindia.com/ref/cup",
  apiURl: "https://devapis.globalhuntindia.com/rcs/",
  urlSource:"source=Ref-Check",
 atsJoburl:"https://devapis.globalhuntindia.com/cds/",
 attachedCandidate:"https://devapis.globalhuntindia.com/ces/",
 candidateUrl:'https://dev.globalhuntindia.com/',
 mailTemplatekey:'CandidateMailTemplate.html',
 internal: 'http://h7.globalhuntindia.com',
 dialer: 'https://h6.globalhuntindia.com',
 crm: 'https://h1.globalhuntindia.com:92',
 analytics: 'http://h2.globalhuntindia.com',
 refCheck: 'https://h3.globalhuntindia.com',
};
