import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../services/auth/auth.service';
import { UserService } from '../services/user.service';
import { LoadingService } from '../services/loading.service';
import { Router } from '@angular/router';
import { SharingDataService } from '../services/sharing-data.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {
  refershTokenKey;
  constructor(private cookieService: CookieService,
    private sharing:SharingDataService
    ) { }

    async ngOnInit(): Promise<any> {
      if (this.cookieService.get('unicode') && !this.cookieService.get('_at')) {
          await this.sharing.getToken();
          return true;
      }
      else if (this.cookieService.get('unicode') && this.cookieService.get('_at')) {
        await this.sharing.getToken();
          return true;
      }
      else {
        await this.sharing.redirectToLogin();
      }
  }

  
}



