import { Injectable } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import jwt from "jwt-decode";

import { AuthService } from './auth/auth.service';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from './notifier.service';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    isLoading = false;
    timeLeft: any;
    interval;
    decoded: any;
    expires: Date;
    refershTokenKey: string;
    constructor(
        private readonly cookieService: CookieService,
        private readonly authService: AuthService,
        private spinner: NgxSpinnerService,
        private notifier:NotifierService

    ) { }

    showLoader() {
        this.spinner.show();
    }

    hideLoader() {
        this.spinner.hide();
    }

    async startTimer() {
        if (this.cookieService.get('_at')) {
            this.decoded = jwt(this.cookieService.get('_at'));
            this.expires = new Date(this.decoded.exp * 1000);
            var milisec = this.expires.getTime() - Date.now() - (60 * 2000);
            this.interval = setTimeout(() => {
                this.refreshToken();
            }, milisec)
        }

    }

    async refreshToken() {
        await this.getRefreshToken();
        if (this.cookieService.get('unicode') && this.refershTokenKey) {
            await this.authService.getRefreshToken(this.refershTokenKey).then(data => {
                if (data.access_token) {
                    this.cookieService.set('_at', data.access_token, { domain: environment.domain });
                    this.cookieService.set('_appauth', data.access_token, { domain: environment.domain });
                    this.startTimer();
                }
            });
        }
    }

    async getRefreshToken() {
        this.refershTokenKey = '';
        const body = {
            "data": {
                "query": {
                    "term": {
                        "code.keyword": {
                            "value": this.cookieService.get('unicode')
                        }
                    }
                }
            },
            "type": "GET"
        }
        await this.authService.refreshToken(body).then(data => {
            if (data.length >= 1) {
                if (data[0]._source.token) {
                    this.refershTokenKey = data[0]._source.token;
                }
            } else {
                this.notifier.showError('Some Error Occured! Please refresh or login again.');
                setTimeout(() => {
                    this.redirectToLogout();
                }, 3000);

            }

        });
    }

    redirectToLogout(): any {
        this.cookieService.deleteAll('/', environment.domain);
        this.cookieService.delete('_appauth');
        this.cookieService.delete('_at');
        this.cookieService.delete('unicode');
        localStorage.clear();
        localStorage.setItem('clearStorage', 'true');
        window.location.href = `https://${environment.socialRedirect_URI}/logout?response_type=${environment.response_type}&client_id=${environment.client_id}&redirect_uri=${environment.redirect_uri}`;
    }


}