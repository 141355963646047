import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RedirectComponent } from './redirect/redirect.component';
import { IndexResolver } from './services/index-resolver.service';
import { AuthGuard } from './services/auth/auth-guard';


const routes: Routes = [
  { path: '', redirectTo: 'redirecting', pathMatch: 'full' },
  {
    path: 'redirecting',
    component: RedirectComponent,
    resolve: { token: IndexResolver },
    
  },

  {
    path: 'app',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivateChild:[AuthGuard]
  },
 
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
