import { Injectable } from '@angular/core';
import { Resolve,Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './auth/auth.service';
import { UserService } from './user.service';
import { LoadingService } from './loading.service';

@Injectable()
export class IndexResolver implements Resolve<boolean> {
  access_token: any;
  refresh_token: any;
  userName: any;
  constructor(
    private _router: Router,
    private cookieService: CookieService,
    private authService: AuthService,
    private userService: UserService,
   private loadingService:LoadingService,
   
  ) {
  }
  async resolve() {
    this.loadingService.showLoader();
    let responseCode;
    const url = window.location.href.split('/?');
    if (url.length > 1) {
      const codeUrl = url[1].split('=');
      responseCode = codeUrl[1];
      await this.authService.getToken(responseCode).then(newdata => {
        if (newdata) {
         this.cookieService.set('unicode', responseCode, { domain: environment.domain });
          this.cookieService.set('_at', newdata.access_token, { domain: environment.domain });
          this.cookieService.set('_appauth', newdata.access_token, { domain: environment.domain });
        
          const refreshTok = {
            "type": "POST",
            "data": {
              "IsAuthenticated": true,
              "code": this.cookieService.get('unicode'),
              "token": newdata.refresh_token,
              "dateCreated": new Date(Date.now()),
              "dateUpdated": new Date(Date.now())
            }
          }
          this.authService.refreshToken(refreshTok).then(data => {
            if (data === 'success') {
            }
          });
}
      });
      await this.userService.getUserLoginDetails(this.cookieService.get('_at')).then(userData => {
        if (userData && Object.keys(userData).length !== 0) {
          window.localStorage.setItem("email", userData.email);
          window.localStorage.setItem('userName', userData.email.split("@")[0]);
          window.localStorage.setItem('phone_number', userData.phone_number);
          const role = userData['custom:role'];
          if (role) {
            window.localStorage.setItem('_ia', role);
          }
        }
      });
      this.hideLoader();
      this._router.navigate(['/app']);
     this.loadingService.startTimer();
      return true;
    } 
   
  }
  hideLoader() {
    this.loadingService.hideLoader();
  }
}
