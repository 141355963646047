import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexResolver } from './services/index-resolver.service';
import { CookieService } from 'ngx-cookie-service';
import { SkipInterceptorService } from './services/skip-interceptor.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptorService } from './services/auth/auth-interceptor.service';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { TimeagoModule } from 'ngx-timeago';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';
import { AuthGuard } from './services/auth/auth-guard';




@NgModule({
    declarations: [AppComponent
    ],
    imports: [
        HttpClientModule,
        TimeagoModule.forRoot(),
        BrowserAnimationsModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        NgxSpinnerModule,
        ToastrModule.forRoot({
            //timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            closeButton: true
        })
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        IndexResolver,
        CookieService,
        AuthGuard,
        SkipInterceptorService,
        GlobalErrorHandlerService
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
