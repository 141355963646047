import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { GlobalErrorHandlerService } from './global-error-handler.service';


@Injectable({
    providedIn: 'root'
})
export class ApiService {
    apiRoot: string;
    constructor(private _httpClient: HttpClient,
    private globalErrorHandlerService:GlobalErrorHandlerService) {
        this.apiRoot = environment.apiURl;
    }
    postRequest(url: string, body: any, headers?: HttpHeaders) {
        let request;
        if (headers) {
            request = this._httpClient.post(url, body, { headers });
        }
        else {
            request = this._httpClient.post(url, body);
        }
        return request.toPromise().then(res => {
            return res;
        })
            .catch(err => {
                this.globalErrorHandlerService.handleError(err);
            });
    }
    getRequest(url: string, headers?: HttpHeaders) {
        let request;
        if (headers) {
            request = this._httpClient.get(url, { headers });
        }
        else {
            request = this._httpClient.get(url);
        }
        return request.toPromise().then(res => {
            return res;
        }).catch(err => {
            this.globalErrorHandlerService.handleError(err);
        });
    }
    putRequest(url: string, body: any, headers?: HttpHeaders) {
        let request;
        if (headers) {
            request = this._httpClient.put(url, body, { headers });
        }
        else {
            request = this._httpClient.put(url, body);
        }
        return request.toPromise().then(res => {
            return res;
        }).catch(err => {
            this.globalErrorHandlerService.handleError(err);
        });
    }
}