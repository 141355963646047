import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from "@angular/common/http";
import { SkipInterceptorService } from '../skip-interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  contentType = "application/json";

  constructor(private _skipInterceptor: SkipInterceptorService) { }

  getToken(unicode) {
    const body = `grant_type=authorization_code&client_id=${environment.client_id}&code=${unicode}&redirect_uri=${environment.redirect_uri}`
    const head = {
      "Content-Type": "application/x-www-form-urlencoded"
    }
    const HeaderDi = {
      headers: new HttpHeaders(head)
    }
    return this._skipInterceptor.postRequest(`${environment.tokenEndPoint}oauth2/token/`, body, HeaderDi.headers)
  }

  refreshToken(body) {
    const bodyData = JSON.stringify(body);
    const headerDict = {
      "Content-Type": this.contentType,
      "x-api-key": environment.refreshtokenKey
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this._skipInterceptor.postRequest(`${environment.refreshToken}`, bodyData, requestOptions.headers);
  }

  getRefreshToken(token) {
    const body = `grant_type=refresh_token&client_id=${environment.client_id}&refresh_token=${token}`

    const head = {
      "Content-Type": "application/x-www-form-urlencoded"
    }
    const HeaderDi = {
      headers: new HttpHeaders(head)
    }
    return this._skipInterceptor.postRequest(`${environment.tokenEndPoint}oauth2/token/`, body, HeaderDi.headers)
  }
}
